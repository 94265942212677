@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Avenir Next LT Pro', sans-serif;
                                                
}

body {
  background-color: #36096d22;

}

/* .h1 {
    @apply text-red-369 flex justify-center font-bold;
} */

/* when menu item link is a active change the 
   text color so user know where at & if user hovers over menu item
   have the color change too. */
ul li.active {
  @apply text-[#37D5D6];
  /* @apply text-[#36096d15]; */
}

.custom-link:hover {
  @apply text-[#37D5D6];
}

/* */

/* */

/* */

/* */
