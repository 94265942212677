/* nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #36096dcf;
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
} */

#navbar-menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar-menu-list li {
  list-style: none;
  padding: 0 20px;
  position: relative;
  color: #fcfcfc;
}

#navbar-menu-list li a {
  text-decoration: none;
  font-size: 1rem;
  color: #fcfcfc;
  font-weight: 600;
  transition: 0.3s ease-in-out;
}

#navbar-menu-list li a:hover,
#navbar-menu-list li a.active {
  color: #37d5d6;
  transition: 0.3s ease-in-out;
}

#navbar-menu-list li a:hover::after,
#navbar-menu-list li a.active::after {
  content: '';
  width: 50%;
  height: 2px;
  background-color: #37d5d6;
  position: absolute;
  bottom: -4px;
  left: 20px;
  transition: 0.3s ease-in-out;
}

ul li.active {
  color: #37D5D6;
}

#mobile {
  display: none;
}

#mobile i {
  color: #fefefe;
  align-items: center;
}


/* Mobile Screens */
@media screen and (max-width: 769px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: 0px;
    width: 300px;
    height: 100vh;
    background: #2a3239;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  /* #navbar.active {
    right: 0px;
  } */

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}
/* @media screen and (max-width: 769px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: #2a3239;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
} */